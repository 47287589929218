import React, { useEffect } from 'react';
import Header from '@components/Header';
import StyledComponent from './index.style';
import Slider from './Slider';
import { shzp, sxzp, xyzp } from '@pages/GlobalEliteRecruitment/Data';
import { RouteComponentProps } from 'react-router-dom';
import EM from '@utils/EM';

export default (props: RouteComponentProps<any>) => {
  useEffect(() => {
    const handle = () => {
      props.history.push('/Resume');
      window.scrollTo(0, 0);
      localStorage.setItem('fromIndex', 'true');
    };

    EM.on('resume', handle);
    return () => {
      EM.removeListener('resume', handle);
    };
  }, [props.history]);
  return (
    <StyledComponent>
      <Header
        img={require('./header.png')}
        title='全球精英招聘'
        titleEn='Global Elite Recruitment'
        content={<></>}
      />
      <div className='content '>
        <div className='section aphorism'>
          <div className='content'>
            <div className='t'>苏格拉底说：教育不是灌输，而是点燃。</div>
            <div className='t'>
              如果你想让一群人造出一艘船，首先你要点燃他们对大海的渴望。
              <br />
              如果说教育是“造船”，你负责点燃孩子对大海的渴望，德普负责点燃你对未来的野心。
            </div>
            <div className='t'>
              每个时代，都需要有人站出来，用超常的思维和前瞻性的视角去推动进步。我们培养懂中国懂世界的创变者，我们也是国际化教育的创变者。
            </div>
            <div className='t'> 安稳还是挑战？坦途还是荆棘？</div>
            <div className='t'>
              我们邀你一起，突破传统，参与这场变革。因为我们是，距离改变世界最近的人。
            </div>
          </div>
        </div>
        <div className='section jd'>
          <div className='title'>岗位需求：</div>
          <div className='content'>
            <div className='t'>
              1.小学语文骨干教师、小学数学骨干教师、小学双语数学教师、小学英语骨干教师、小学双语科学教师；
            </div>
            <div className='t'>
              2.国际高中语文教师、国际高中英语教师、双语数学教师、双语生物教师、双语经济教师、双语化学教师、双语地理教师、体育教师；
            </div>
            <div className='t'>
              3. 初中语文骨干教师、初中数学骨干教师、初中英语骨干教师；
            </div>
            <div className='t'> 4. 图书管理员；</div>
          </div>
        </div>
        {/*<div className='section jd'>*/}
        {/*  <div className='title'>应聘资格：</div>*/}
        {/*  <div className='content'>*/}
        {/*    <div className='t'>1. 全日制大学本科及以上，所教学科相关专业；</div>*/}
        {/*    <div className='t'>*/}
        {/*      2.*/}
        {/*      具备扎实深厚的学科专业背景和优秀的专业素养，具有较强的教学科研能力；*/}
        {/*    </div>*/}
        {/*    <div className='t'>*/}
        {/*      3. 有相关任教经历或相关岗位工作经验且业绩突出者优 先；*/}
        {/*    </div>*/}
        {/*    <div className='t'>*/}
        {/*      4.*/}
        {/*      双语老师需口语纯正，能熟练运用英语教学，已过专业八级或英语六级者优先；有IGCSE/A-Level/AP等国际课程任教经历且业绩突出者优先；有海外教育背景者优先；*/}
        {/*    </div>*/}
        {/*    <div className='t'>*/}
        {/*      5.*/}
        {/*      热爱教育事业、身体健康，形象气质佳，综合素质强、具有较强的表达沟通能力、团队精神。*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className='section jd'>
          <div className='title'>薪酬&发展：</div>
          <div className='content'>
            <div className='subTitle'>———超一流薪资</div>
            <div className='t'>
              行业顶尖薪酬水平，高层次人才薪资一岗一议，五大教学津贴，绩效奖金加年终奖，高额教师竞赛、科研成果奖，科研课题研究经费。
            </div>
            <div className='subTitle'>———华为同款TUP激励计划</div>
            <div className='t'>
              中长期员工期权激励计划，按贡献分配期权，教师参与 分红。
            </div>

            <div className='subTitle'>———全方位福利</div>
            <div className='t'>
              五险一金、交通补贴、工作餐补贴、话费补贴、节日慰问礼金、员工关怀礼金、年度VIP高规格体检。
            </div>

            <div className='subTitle'>———多重职业晋升通道</div>
            <div className='t'>
              职称评审双轨保障，参与公办学校同等的专业技术职称评审，享受校内职称认定，十二级岗位晋升，三大培训方式四大培训方向。
            </div>

            <div className='subTitle'>———全方位生活保障</div>
            <div className='t'>
              国家法定节假日，带薪寒暑假，酒店式教职工公寓拎包入住，图书馆、健身房、咖啡厅…
            </div>
            <div className='subTitle'>——子女超低折扣入读</div>
            <div className='t'>
              教职工子女可超低折扣入读本校，从小学到高中，享受最优质的教育资源。
            </div>
          </div>
        </div>
      </div>

      <div className='content'>
        <div className='section aphorism'>
          <div className='content'>
            <div className='t'>苏格拉底说：最优秀的人就是你自己。</div>
            <div className='t'>
              我们用最有力的怀抱、最优越的条件欢迎你。在这里，你不会孤单，有一群志同道合的人，和你一起做自己，保持热情、保持大胆，点燃对未来的野心。
            </div>
          </div>
        </div>
      </div>
      <div className='jbblock'>
        <div className='title'>应聘方式：</div>
        <div className='t'>
          ● 请将个人简历、生活近照1张发送至学校招聘专用邮箱hr@dpfls.com
        </div>
        <div className='t'>● 邮件标题备注：应聘学科+应聘学部+姓名</div>
        <div className='t'>
          ● 也可点击下方“立即应聘”按钮，填写基本信息，我们将会与您联系。
        </div>
      </div>
      <Slider items={shzp} title='社会招聘' />
      <Slider items={xyzp} title='校园招聘' />
      <Slider items={sxzp} title='实习招聘' />
    </StyledComponent>
  );
};
