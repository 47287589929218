import React from 'react';
import HeaderEn from '@components/HeaderEn';
import StyledComponent from './index.style';
import {
  SchoolList,
  activities,
  ProfiletTable,
  CourseTable,
  HonorsCurriculum
} from './data';
import classnames from 'classnames';

export default () => {
  return (
    <StyledComponent>
      <HeaderEn
        img={require('./header.png')}
        title='Chongqing Depu 
        Foreign Language 
        School
        '
        titleEn=''
        content={
          <>
            <div>No.16 Ruilong Road</div>
            <div>Banan District</div>
            <div>Chongqing City</div>
            <div>China</div>
          </>
        }
      />
      <div className='_content'>
        <div className='section profile'>
          <div className='title'>The School and Community</div>
          <div className='common_item text_item'>
            Chongqing Depu Foreign Language School (DEPU), founded in 2015 by
            Chongqing Depu Educational Investment Co., Ltd., is a private
            international boarding school located in Banan District, Chongqing,
            China. DEPU offers a consecutive Grade 1-12 curriculum supported by
            four education units, namely Bilingual Boarding Primary School
            (Grade 1-6), Smart Junior High School and Bilingual Junior High
            School (Grade 7-9), and International Senior High School (Grade
            10-12).
          </div>
          <div className='common_item text_item'>
            Located in southwestern China and known as “the Mountain City”,
            Chongqing is a provincial-level administrative region and a
            municipality of China, an economic and financial center in the upper
            reaches of the Yangtze River, an important advanced manufacturing
            center, and has a population of 32.12 million.
          </div>

          <div className='table'>
            <div className='tb one'>
              <div className='sub'>{ProfiletTable.DepuSchool.sub}</div>
              {ProfiletTable.DepuSchool.list.map((item, index) => {
                return (
                  <div className='item' key={index}>
                    <div className='wrap'>
                      <span className='num'>{item.numleft}</span>
                      {item.name}
                      <span className='num'>{item.num}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='tb two'>
              <div className='sub'>
                {ProfiletTable.ItnlSeniorHightSchool.sub}
              </div>
              {ProfiletTable.ItnlSeniorHightSchool.list.map((item, index) => {
                return (
                  <div className='item' key={index}>
                    {item.name}
                    <span>{item.num}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className='section core'>
          <div className='title'>Core Value</div>
          <div className='text_item core_item'>
            <span>Motto:</span>
            Cultivated in the world, character will become universal.
          </div>
          <div className='text_item core_item'>
            <span>Mission:</span>
            Embracing the fast-changing world and uncertain future, DEPU is
            committed to sympathizing with parents’ worries, returning to the
            essence of education, innovating education and administration,
            forging a consecutive Grade 1-12 curriculum integrating the
            highlights of Chinese and western education, and cultivating healthy
            and joyful innovators and changemakers who know China and the world.
          </div>
        </div>
        <div className='section credit'>
          <div className='title'>Accreditation</div>
          <div className='common_item text_item'>
            #41 in Hurun Education Top 100 International Schools in China 2022
          </div>
          <div className='common_item text_item'>
            Chongqing Cambridge International Education Centre accredited by
            Cambridge Assessment International Education (CIE)
          </div>
          <div className='common_item text_item'>
            Pearson Edexcel Academic Qualification and Exam Centre
          </div>
          <div className='common_item text_item'>
            Cambridge Professional Development Centre (PDQ) accredited by CIE
          </div>
        </div>
        <div className='section faculty'>
          <div className='title'>
            The Faculty of International Senior High School
          </div>

          <div className='common_item text_item'>
            100% have Teacher Certification and various professional
            qualifications, including First-grade Teacher, Senior Teacher, and
            Cambridge Professional Development Qualifications (PDQ).
          </div>
          <div className='common_item text_item'>
            The teachers graduated from renowned universities, including
            Columbia University, London School of Economics and Political
            Science, University College London, University of Toronto, National
            University of Singapore, Nanyang Technological University,
            University of Edinburgh, University of Manchester, University of
            Liverpool, Monash University, University of Sheffield, University of
            the Arts London, The Education University of Hong Kong, Zhejiang
            University, University of Chinese Academy of Sciences, Sun Yat-sen
            University, Sichuan University, Southwest University, etc.
          </div>
        </div>

        <div className='section curriculum'>
          <div className='title'>Curriculum</div>
          <div className='intro text_item'>
            The International Senior High School of DEPU adopts the following
            curriculum systems:
          </div>
          <div className='text_item'>
            <div className='common_item'>
              Oriented International Curriculum System:
            </div>
            <div className='inner'>
              We offer 24 IGCSE and International A-Level courses administered
              by CIE and Pearson Edexcel, collaborated with 8 AP courses in
              total, where A-Level Chinese and Mathematics are compulsory
              courses, and a wide range of courses in natural sciences, social
              sciences, and art are elective.
            </div>
          </div>
          <div className='text_item'>
            <div className='common_item'>
              Chinese High School Curriculum System:
            </div>

            <div className='inner'>
              This system includes every subject offered in public Chinese high
              schools, and all the courses are compulsory. After completing this
              curriculum, students will sit in the Hui Kao and be conferred the
              High School Graduation Certificate.
            </div>
          </div>
          <div className='table1'>
            <div className='tb one'>
              <div className='sub'>{CourseTable.ALevelCourses.sub}</div>
              {CourseTable.ALevelCourses.list.map((item, index) => {
                return (
                  <div
                    className={classnames([
                      [2, 6].includes(index) ? 'long' : '',
                      index === 5 ? 'double' : '',
                      'item'
                    ])}
                    key={index}>
                    <span>{item}</span>
                  </div>
                );
              })}
            </div>
            <div className='tb two'>
              <div className='sub'>{CourseTable.APCourses.sub}</div>
              {CourseTable.APCourses.list.map((item, index) => {
                if (index !== 5) {
                  return (
                    <div
                      className={classnames([
                        [5].includes(index) ? 'long' : '',
                        'item'
                      ])}
                      key={index}>
                      <span>{item}</span>
                    </div>
                  );
                } else {
                  return (
                    <div className='long double item' key='1000'>
                      <span>
                        AP Physics C: Electricity
                        <br /> and Magnetism
                      </span>
                    </div>
                  );
                }
              })}
            </div>
            <div className='tb three'>
              <div className='sub'>{CourseTable.CHHSC.sub}</div>
              {CourseTable.CHHSC.list.map((item, index) => {
                return (
                  <div
                    className={classnames([
                      [9, 10, 11].includes(index) ? 'long' : '',
                      index === 5 ? 'double' : '',
                      'item'
                    ])}
                    key={index}>
                    <span>{item}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='common_item text_item'>Honors Curriculum:</div>
          <div className='table2'>
            <div className='type'>
              <div className='item sub'>{HonorsCurriculum.type.name}</div>
              <div className='item content content1'>
                <span>{HonorsCurriculum.type.content1}</span>
              </div>
            </div>
            <div className='desc'>
              <div className='item sub'>{HonorsCurriculum.desc.name}</div>
              <div className='item content content1'>
                <span>{HonorsCurriculum.desc.content1}</span>
              </div>
            </div>
          </div>
          <div className='remark'>
            <div className='sub'>* The Allusion to David Club:</div>
            <div className='text_item'>
              In the war between Israel and Philistia, the Philistines sent out
              their most powerful champion Goliath, who challenged the
              Israelites to a fight. While all the Israelites were frightened
              off, David, the young shepherd, rose to the challenge and
              triumphed over Goliath with only his shepherd equipment.
              <br />
              By establishing the David Club, DEPU expects to cultivate the
              students into “David-like” talents who are brave, confident, and
              practical, who dare to challenge authority, and able to tackle the
              crisis in an innovative manner.
            </div>
          </div>
          <div className='text_item'>
            <div className='common_item'>
              Extended Project Qualification Program (EPQ):
            </div>
            <div className='item '>
              Grade 11 students with scientific research ability and exploratory
              spirit also have opportunities to participate in the personalized
              EPQ projects for a research study. EPQ is a research-based
              learning certification program recommended by the British Ministry
              of Education and the Universities and Colleges Admissions Service
              (UCAS). The student partaking in the EPQ will be required to make
              a research-oriented study into a topic of his/her interest,
              conclude the study process and research results into either a
              5,000-word report or a displayable 'product' plus a report of
              1,000 words, and finally deliver a 15-minute presentation. After
              panel review, the student will obtain the EPQ certificate and
              rating. The EPQ program can not only cultivate students' abilities
              in independent learning, time management, and problem-solving but
              also equip students with complete academic methodology and
              academic research competence. DEPU's EPQ program is officially
              accredited by Pearson Edexcel.
            </div>
          </div>
          <div className='text_item'>
            <div className='common_item'>Featured Courses:</div>
            <div className='item feature'>University Guide Course</div>
            <div className='item feature'>Global Perspective</div>
            <div className='item feature'>Academic Writing</div>
          </div>
          <div className='text_item'>
            <div className='common_item'> Co-Curriculum Activity (CCA)</div>
            <div className='inner text_item'>
              CCA is a unique activity curriculum of DEPU. Offered jointly with
              the leading teams in various industries, it covers 33 categories,
              including but not limited to: sports, arts, humanities, clubs,
              research, technology, and rescue. Courses cover Street Dance,
              Kendo, Fencing, Yoga, Acting, Drones, Ukulele, Film Workshop,
              French, Spanish, Japanese, Golf, Racket Ball, Baseball, Badminton,
              Basketball, Soccer, Tennis, JAVA Programming, and more.
            </div>
          </div>
        </div>

        <div className='section activities'>
          <div className='title'>Student Activities</div>
          <div className='intro text_item'>
            Over 100 student clubs and a wide variety of school-level
            extracurricular activities can be found on the campus of DEPU,
            giving students a colorful campus life (some of the activities are
            as follows).
          </div>
          <div className='table'>
            <div className='tb one'>
              <div className='sub'>{activities.ArtandSports.title}</div>
              {activities.ArtandSports.list.map((item, index) => {
                return (
                  <div className='item' key={index}>
                    {item}
                  </div>
                );
              })}
            </div>
            <div className='tb two'>
              <div className='sub'>{activities.Academics.title}</div>
              {activities.Academics.list.map((item, index) => {
                return (
                  <div className='item' key={index}>
                    {item}
                  </div>
                );
              })}
            </div>
            <div className='tb three'>
              <div className='sub'>{activities.StudentClubs.title}</div>
              {activities.StudentClubs.list.map((item, index) => {
                return (
                  <div key={index} className='item'>
                    <span>{item}</span>
                  </div>
                );
              })}
            </div>
            <div className='tb four'>
              <div className='sub'>{activities.CareerPlanning.title}</div>
              {activities.CareerPlanning.list.map((item, index) => {
                return (
                  <div className='item' key={index}>
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className='section graduates'>
          <div className='title'>Graduates</div>
          <div className='intro text_item'>
            Over the years, the International Senior High School of DEPU has
            achieved a graduation rate of 99%, a post-secondary persistence rate
            of 98%, and a university acceptance rate of 100%.
          </div>
          <div className='intro text_item'>
            Our graduates have been admitted into renowned universities of
            various countries and regions, including the United States, the
            United Kingdom, Canada, Australia, the Netherlands, Ireland, New
            Zealand, Hong Kong, Singapore, Japan, Malaysia, and so on. The
            following is a list of the universities that admit our students.
          </div>
          <div className='school_list'>
            {SchoolList.map((school, index) => {
              return <div key={index}>{school}</div>;
            })}
          </div>
        </div>

        <div className='section administrators'>
          <div className='title'>Administrators</div>
          <div className='info'>
            <div className='name'>Lingke Xu (Roger), Principal</div>
            <div className='email'>roger_xu@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>
              Ren Zhang (Amy), Principal of International Senior High School
            </div>
            <div className='email'>amy_zhang@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>
              Xiaohan Lei (Tracy), Academic Affair Director
            </div>
            <div className='email'>tracy_lei@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>
              Shitao Chen (Heathcliff), Moral Education Director
            </div>
            <div className='email'>heathcliff_chen@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>Yan Tan (Grace), Administrative Director</div>
            <div className='email'>grace_tan@dpfls.com</div>
          </div>
          <div className='title-small'>College Counseling Center</div>
          <div className='info'>
            <div className='name'>Dan Hou (Sophie), Director</div>
            <div className='email'>Sophie_hou@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>Yanying Li (Kathy)</div>
            <div className='email'>liyanying@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>Yin Wang (Jessica)</div>
            <div className='email'>wangyin@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>Yiyi Liu (Gracia)</div>
            <div className='email'>liuyiyi@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>Yuling Qi (Maggie)</div>
            <div className='email'>qiyuling@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>Xiaoqi Li (Mia)</div>
            <div className='email'>lixiaoqi@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>Yanyu Wang (Irene)</div>
            <div className='email'>wangyanyu@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>Zhuo Zeng (Joey)</div>
            <div className='email'>zengzhuo@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>Qing Yan (Sunny)</div>
            <div className='email'>yanqing@dpfls.com</div>
          </div>
          <div className='info'>
            <div className='name'>General Affairs</div>
            <div className='email'>academic-affairs@dpfls.com</div>
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
