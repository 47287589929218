import React, { useEffect } from 'react';
import styled from 'styled-components';
import Tags from './Tags/Tags';
import ImgBlocks from './ImgBlocks';
import Slide from './Slide';
import Slide2 from './Slide2';
import Banner from './banner';
import Video from './Video';
import { RouteComponentProps } from 'react-router-dom';
import EM from '@utils/EM';

const Content = styled((props: RouteComponentProps<any>) => {
  const { staticContext, ...rest } = props;
  useEffect(() => {
    EM.emit('toggleHome', true);
    return () => {
      EM.emit('toggleHome', false);
    };
  }, []);
  return (
    <div {...rest}>
      <Banner />
      <Tags {...rest} id={'tags-top'} />
      <div className='ChongqingCambridgeInternationalCenter'>
        {/*<div className='text1'>剑桥大学国际考评部授权的</div>*/}
        <div className='text2 fontEn'>
          Chongqing Cambridge International Education Center
        </div>
        <div className='text3'>重庆剑桥国际中心</div>
        <div className='text4'>
          重庆德普外国语学校被剑桥大学国际考评部授权为全球考试中心，并命名为“重庆剑桥国际中心”，也是西南地区唯一的剑桥教师职业发展中心（PDQ中心）。{' '}
        </div>
        <div className='text4 last'>
          此外，德普还获得美国大学理事会（College
          Board）、英国爱德思（Edexcel）、英国文化教育协会的认证。
        </div>
        <div
          className='readmore'
          onClick={() => {
            props.history.push('/CambridgeProfessionalDevelopmentCentre');
            window.scrollTo(0, 0);
            localStorage.setItem('fromIndex', 'true');
          }}>
          Read More {'>>'}
        </div>
      </div>
      <ImgBlocks
        {...rest}
        data={[
          {
            img: require('./banner/03双语寄宿小学.jpg'),
            text1: '双语寄宿小学',
            text2: 'Bilingual Boarding Primary',
            activeColor: '#D89D07',
            to: '/BilingualBoardingPrimary'
          },
          {
            img: require('./banner/04小班初中.jpg'),
            text1: '小班初中',
            text2: 'Smart Junior',
            activeColor: '#B30010',
            to: '/SmartJunior'
          },
          {
            img: require('./banner/05双语初中.jpg'),
            text1: '双语初中',
            text2: 'Bilingual Junior',
            activeColor: '#0085B5',
            to: '/BilingualJunior'
          },
          {
            img: require('./banner/07国际高中.jpg'),
            text1: '国际高中',
            text2: 'International Senior',
            activeColor: '#65214A',
            to: '/InternationalSenior'
          },
          {
            img: require('./banner/06 IGB.jpg'),
            text1: '国际高中衔接班',
            text2: 'IGB Class',
            activeColor: '#0DB900',
            to: '/IGBClass'
          }
        ]}
      />
      <div className='HonoraryMentors '>
        <div className='text2 fontEn'>Honorary Mentors</div>
        <div className='text3'>荣誉导师团</div>
        <div className='text4'>
          教育是点燃，而不只是知识传授。灵魂的滋养，不能藉由单一的营养。
        </div>
        <div className='text4 last'>
          我们请来不同领域的精英大咖、意见领袖、匠人怪杰，做客德普，组成德普的荣誉导师团，为孩子们开启一扇扇眺望不同世界的窗。然后，有一天，奋不顾身地走进去。
        </div>
        <div
          className='readmore'
          onClick={() => {
            props.history.push('/EliteFaculties?t=5');
          }}>
          Read More {'>>'}
        </div>
      </div>
      <div className='fast-facts'>
        <div className='text1 fontEn'>Fast Facts</div>
        <div className='text2'>数字德普</div>
        <div className='text3'>10组数据，快速了解德普的关键信息。</div>
      </div>
      <Slide id={'keywords'} />
      <Video
        text1='德普初中生的一天'
        text2=''
        src={require('./初中生的一天.mp4')}
        poster={require('./初中生的一天.jpg')}
        controls={true}
      />
      <div
        className='readmore video'
        onClick={() => {
          window.open('https://www.bilibili.com/video/av91517664?p=9');
        }}>
        点击查看更多视频
      </div>
      <ImgBlocks
        {...rest}
        data={[
          {
            img: require('./18德普毕业生@2x.png'),
            text1: '德普毕业生',
            text2: 'DEPU Graduates',
            activeColor: '#D89D07',
            to: '/Graduates'
          },
          {
            img: require('./banner/08师资.jpg'),
            text1: '精英师资',
            text2: 'Elite Faculties',
            activeColor: '#B30010',
            to: '/EliteFaculties'
          },
          {
            img: require('./14国际高中@2x.png'),
            text1: '全球精英招聘',
            ext: <div>JOIN US {'>>'}</div>,
            text2: 'Global Elite Recruitment',
            activeColor: '#0085B5',
            to: '/GlobalEliteRecruitment'
          }
        ]}
      />
      <Slide2 />
      <Tags {...rest} id={'tags-bottom'} />
    </div>
  );
})`
  > .headerImg {
    width: 750px;
    height: 1013px;
  }
  > .fast-facts {
    padding: 20px 0 0 41px;
    > .text1 {
      font-weight: bold;
      font-size: 62px;
      color: #ba0001;
    }
    > .text2 {
      font-weight: normal;
      font-size: 42px;
      line-height: 58px;
      text-align: left;
      color: #be2a2a;
    }
    > .text3 {
      font-weight: normal;
      font-size: 26px;
      text-align: left;
      color: #666;
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }
  > .ChongqingCambridgeInternationalCenter {
    padding: 80px 41px;
    text-align: left;
    word-break: break-word;
    > .text1 {
      font-weight: normal;
      font-size: 28px;
      color: #000;
      padding-bottom: 15px;
    }
    > .text2 {
      font-weight: bold;
      font-size: 62px;
      color: #99d501;
      padding-bottom: 15px;
    }
    > .text3 {
      font-weight: normal;
      font-size: 42px;
      letter-spacing: 0.04em;
      line-height: 42px;
      padding-bottom: 40px;
      color: #99d501;
    }
    > .text4 {
      font-weight: 300;
      font-size: 26px;
      line-height: 50px;
      letter-spacing: -0.01em;
      color: #666666;
      &.last {
        padding-top: 50px;
      }
    }
  }
  > .HonoraryMentors {
    padding: 80px 41px;
    text-align: left;
    word-break: break-word;
    > .text1 {
      font-weight: normal;
      font-size: 62px;
      color: #262626;
      padding-bottom: 15px;
    }
    > .text2 {
      font-weight: bold;
      font-size: 62px;
      //color: #019cde;
    }
    > .text3 {
      //color: #009dda;
      font-weight: normal;
      font-size: 42px;
      letter-spacing: 0.04em;
      line-height: 42px;
      padding-top: 10px;
      padding-bottom: 30px;
    }
    > .text4 {
      font-weight: 300;
      font-size: 28px;
      line-height: 50px;
      letter-spacing: -0.01em;
      color: #666666;
      &.last {
        padding-top: 40px;
      }
    }
  }
  .readmore {
    font-weight: bold;
    font-size: 28px;
    letter-spacing: -0.01em;
    color: #019cde;
    line-height: 50px;
    padding-top: 10px;

    &.video {
      position: relative;
      top: -15px;
      padding: 0 30px;
      text-align: center;
    }
  }
`;

export default (props: RouteComponentProps<any>) => (
  <>
    <Content {...props} />
  </>
);
