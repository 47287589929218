import React from 'react';
import Header from '@components/Header';
import StyledComponent from './index.style';

export default () => {
  return (
    <StyledComponent>
      <Header
        img={require('./header.png')}
        title='剑桥教师职业发展中心'
        titleEn='Cambridge Professional Development Centre'
        content={<></>}
      />
      <div className='content '>
        <div className='section graduate'>
          <div className='title'>什么是剑桥教师职业发展中心？</div>
          <div className='content'>
            <div className='t'>
              德普被剑桥大学国际考评部（Cambridge International Education）
              授权为西南地区唯一的剑桥国际教师职业发展中心(Cambridge
              Professional Development Centre
            </div>
            <div className='t'>
              剑桥国际教师职业发展中心（PDQ中心）是剑桥大学国
              际考评部研发，由伦敦大学学院教育研究院（UCL-IOE，
              该教育研究院连续三年在世界大学教育领域排名第一）
              论证和支持的在岗培训项目。
            </div>
            <div className='t'>
              PDQ是根据剑桥标准而设计的教师专业能力发展与提升
              项目，它着眼于在职教师的整体发展。剑桥大学国际考
              评部在PDQ中心的审核上有着极其严苛的标准。
            </div>
          </div>
        </div>
      </div>
      <div className='content '>
        <div className='section graduate'>
          <div className='title'>PDQ课程，能带来什么？？</div>
          <div className='content'>
            <div className='t'>
              教师参加剑桥国际教师职业发展认证课程(即PDQ课程)
              可取得全球认可的剑桥PDQ学位证书。
            </div>
            <div className='t'>
              通过这个课程，老师们可获得最新的教学教研理念，提高自己的专业素养，从而为学生提供更好的教学服务和教学质量。老师们也将有机会获得相当于伦敦大学学院教育学硕士的同等学力。
            </div>
          </div>
        </div>
      </div>
      <div className='content '>
        <div className='section graduate'>
          <div className='title'>普思考试示范学校</div>
          <div className='content'>
            <div className='t'>
              2019年3月，德普率先被英国文化教育协会授予为重庆
              地区首批“普思考试示范学校”。德普学子在自己的学校
              里，就可参加普思考试。
            </div>
            <div className='t'>
              和雅思一样，普思也由英国文化教育协会（British Cou
              ncil，简称BC）权威打造，可快速精准检测英语水平，
              接轨国际测评体系。普思考试主要针对小年龄段（17岁
              以下）的学生，用于检测他们当前所达到的英语能力。
              其在题型、话题等多个方面均与雅思有共通之处，堪称
              雅思的“低龄版”。参加普思考试可为之后的雅思学习和 考试做准备。
            </div>
          </div>
        </div>
      </div>
      <div className='content '>
        <div className='section graduate'>
          <div className='title'>美国大学理事会认证学校</div>
          <div className='content'>
            <div className='t'>
              同时，德普还是美国大学理事会（The College Board）
              认证学校，被美国大学理事会授权开设AP，PSAT/NMSQT, SAT, and ACT
              课程。
            </div>
            <div className='t'>
              美国大学理事会(The College Board)是美国最大的非政
              府性教育组织。作为AP考试的命题机构，它主要负责批
              准高中开设AP课程并在授权的高中主办全球统一考试， 培训 AP教师并授予
              AP 教师资质。只有经过其认证的AP
              课程，才能在学生大学申请中发挥应有的效力。
            </div>
          </div>
        </div>
      </div>
      <div className='content '>
        <div className='section graduate'>
          <div className='title'>爱德思官方认证学校</div>
          <div className='content'>
            <div className='t'>
              此外，德普还获得了英国爱德思（Edexcel）的官方权威认证。德普学生可根据自身情况，自由选择Cambridge
              International A-Level和爱德思A-Level考试。
            </div>
            <div className='t'>
              爱德思（Edexcel）即英国爱德思国家职业学历与学术考试机构，是英国最大的颁证机构，也是英国唯一的具备颁发学术类考试证书和职业教育资格证书的机构。
              <br />
              爱德思（Edexcel）A-Level考试为全球同步考试，所用试卷全球统一，成绩被所有A-Level高中、考试机构及学校所认可。
            </div>
          </div>
        </div>
      </div>

      <div className='content '>
        <div className='section graduate'>
          <div className='title'>Cognia认证学校</div>
          <div className='content'>
            <div className='t'>
              作为全球最大的非营利性国际学校权威认证机构，Cognia拥有125年的历史，其前身是AdvancED，总部位于美国。目前服务于全球85个国家，超过36,000所学校，近2,500万学生和500万教育者。
            </div>
            <div className='t'>
              通过Cognia权威认证意味着，德普在各个方面都达到了全球教育发展趋势和挑战的衡量标准，教学质量得到国际权威教育机构的认可。获得Cognia认证后，学生的成绩单可得到
              Cognia的盖章背书，在申请大学时，更容易得到海外名校的认可。
            </div>
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
