import React from 'react';
import Header from '@components/Header';
import Pop from '@components/Pop';
import StyledComponent from './index.style';
import { Course, Feature, Outside } from '@pages/SmartJunior/Data';
import { RouteComponentProps } from 'react-router-dom';

export default (props: RouteComponentProps<any>) => {
  return (
    <StyledComponent>
      <Header
        img={require('./header.png')}
        title='小班初中'
        titleEn='Smart Junior'
        content={
          <>
            <div>世界因参差而美丽，</div>
            <div>教育需要回归因材施教；</div>
            <div>学生不是复制品，</div>
            <div>关注每个孩子的成长。</div>
          </>
        }
      />
      <div className='content '>
        {/*<div className='section graduate'>*/}
        {/*  <div className='title'>我们的毕业生</div>*/}
        {/*  <div className='content'>*/}
        {/*    <div className='t1'>中考成绩</div>*/}
        {/*    <div className='t2'>*/}
        {/*      <span>660分以上26.04%</span>*/}
        {/*      <br />*/}
        {/*      <span>600分以上72.9%</span>*/}
        {/*      <br />*/}
        {/*      <span>联招线以上92.7%</span>*/}
        {/*    </div>*/}
        {/*    <div className='t3'>*/}
        {/*      其中，何思璇同学获得裸分710的高分，岳邦君同学在小托福考试中以满分的成绩获得亚洲区第一名。*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className='section course'>
          <div className='title'>我们的课程</div>
          <div className='content'>
            <div className='t1'>
              “给我一个支点，我可以撬起整个地球。”我们相信，孩子们不是缺少实力，而是缺少机会。小班初中杠杆课程，让每个孩子找到属于自己的“支点”。
            </div>
            <div className='t2'>杠杆课程（LEVER）</div>
            <div className='t3'>
              {Course.map(item => {
                return (
                  <Pop
                    className='item'
                    key={item.right}
                    content={item.content}
                    leftVW={item.leftVW}>
                    <div className='left'>
                      {item.left.split('').map((x, index) => {
                        return <span key={index}>{x}</span>;
                      })}
                    </div>
                    <div className='right'>{item.right}</div>
                  </Pop>
                );
              })}
            </div>
          </div>
        </div>
        <div className='section feature'>
          <div className='title'>我们的特色</div>
          <div className='content'>
            {Feature.map((x, index) => {
              return (
                <div className='item' key={index}>
                  <div className='title'>{x.title}</div>
                  <div className='content'>{x.content}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='section outside'>
          <div className='title'>我们的课外</div>
          <div className='content'>
            <div className='t1'>
              如果学习只剩下考试和分数，那么你将永远看不到也融不进这个多彩的世界！我们想在学习的同时，为你打开世界的另一扇窗。我们既要考得了100分，也要跑得了100米；既要在考场上叱咤风云，也要在操场上恣意奔驰。
            </div>
            <div className='t2'>
              科技探索，职业体验，用感恩的心回馈社会，在这里，你所有的激情澎湃，都不会只是说说而已。我们给你舞台！
            </div>
            {Outside.map((x, xIndex) => {
              return (
                <div className='block' key={xIndex}>
                  <div className='title'>{x.title}</div>
                  <div className='content'>
                    {x.items.map((y, yIndex) => {
                      return (
                        <div className='item' key={yIndex}>
                          {y}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='section teacher'>
          <div className='title'>我们的老师</div>
          <div className='content'>
            <div className='t t1'>
              在这里，高级教师占比19%，一级教师占比55%，52%的老师拥有研究生及以上文凭；教师平均年龄34岁，平均教龄10年；
            </div>
            <div className='t'>
              在各类赛课比赛中，仅一等奖，老师们就有“国家级12人次，市级23人次，区级35人次”的佳绩；
            </div>
            <div className='t'>其中</div>
            <div className='t'>肖红静老师获全国优质课一等奖；</div>
            <div className='t'>
              数学、语文、心理、历史、政治等科目老师在市级赛课中获一等奖；
            </div>
            <div className='t'>
              茹妮妮、贺晓明、马立平、陈瑶等老师获一师一优课部级优课奖。
            </div>
            <div
              className='link'
              onClick={() => {
                props.history.push('/EliteFaculties?t=2');
                window.scrollTo(0, 0);
                localStorage.setItem('fromIndex', 'true');
              }}>
              点击查看师资详细介绍
            </div>
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
