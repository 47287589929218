import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { hot, setConfig } from 'react-hot-loader';
import '@utils/imgsPreventDefault';
import styled from 'styled-components';
import Appointment from '@components/Appointment';
import IGBClass from '@pages/IGBClass';
import BilingualBoardingPrimary from '@pages/BilingualBoardingPrimary';
import English from '@src/pages/English';
import SmartJunior from '@pages/SmartJunior';
import BilingualJunior from '@pages/BilingualJunior';
import InternationalSenior from '@pages/InternationalSenior';
import GlobalEliteRecruitment from '@pages/GlobalEliteRecruitment';
import Scholarships from '@pages/Scholarships';
import Brief from '@pages/Brief';
import Graduates from '@src/pages/Graduates';
import EliteFaculties from '@pages/EliteFaculties';
import Resume from '@pages/Resume';
import CambridgeProfessionalDevelopmentCentre from '@pages/CambridgeProfessionalDevelopmentCentre';
import Index from '@pages/Index';
import OutOfService from '@pages/OutOfService';
import Encyclopedia from '@pages/Encyclopedia';
import EM from '@utils/EM';

import './App.less';

setConfig({
  pureRender: true // RHL will not change render method
});

const routes = [
  { path: '/', component: Index, exact: true },
  { path: '/m', component: Index, exact: true },
  { path: '/IGBClass', component: IGBClass },
  { path: '/BilingualBoardingPrimary', component: BilingualBoardingPrimary },
  { path: '/English', component: English },
  { path: '/SmartJunior', component: SmartJunior },
  { path: '/BilingualJunior', component: BilingualJunior },
  { path: '/InternationalSenior', component: InternationalSenior },
  { path: '/GlobalEliteRecruitment', component: GlobalEliteRecruitment },
  { path: '/Scholarships', component: Scholarships },
  { path: '/Brief', component: Brief },
  { path: '/Graduates', component: Graduates },
  { path: '/EliteFaculties', component: EliteFaculties },
  { path: '/Resume', component: Resume },
  {
    path: '/CambridgeProfessionalDevelopmentCentre',
    component: CambridgeProfessionalDevelopmentCentre
  },
  {
    path: '/encyclopedia',
    component: Encyclopedia
  }
];

export function animateScroll(
  element: HTMLElement | null,
  offset = 130,
  behavior: 'smooth' | 'auto' = 'smooth'
) {
  if (!element) {
    return;
  }
  window.scrollTo({
    behavior: 'smooth',
    left: 0,
    top: element.offsetTop - offset
  });
  // window.scrollTo(0, element.offsetTop - 130);
}

const App = () => {
  const [isShowAppointment, setIsShowAppointment] = useState<boolean>(false);
  const [showStatus, setShowStatus] = useState<boolean>(true);
  const [hideHome, setHideHome] = useState<boolean>(false);
  const footerItems = [
    {
      text: '年度主题',
      onClick: () => {
        // animateScroll(document.querySelector('#keywords'));
        window.location.href =
          'https://mp.weixin.qq.com/s?__biz=MzI5NDA2Mzg1Mg==&mid=2650191082&idx=1&sn=c91513a81be62e28e27c8a5417e0509a&chksm=f46a8f69c31d067f874ae7d3919f6694e4865aa6985ffe371795bf621977f6199223166d7a37#rd';
      },
      img: 0
    },
    {
      text: '咨询登记',
      onClick: () => {
        window.location.href = 'https://www.aliwork.com/o/mianmidenglu2';
        // window.location.href = 'https://depu.schoolis.cn/Form?linkId=16084';
      },
      img: 2
    },
    {
      text: '预约参观',
      onClick: () => {
        window.location.href = 'https://www.aliwork.com/o/zixunlist1';
        // setIsShowAppointment(true);
      },
      img: 1
    },

    {
      text: '教育理想国',
      onClick: () => {
        window.location.href =
          'https://mp.weixin.qq.com/s?__biz=MzI5NDA2Mzg1Mg==&mid=2650173484&idx=1&sn=677e482411b8a562d6c2bb2525627381&chksm=f46a4bafc31dc2b96b670b0afaf18285c20b1d12d64d93166316f42263dcbbb450d59865e49c#rd';
      },
      img: 3
    }
  ];

  useEffect(() => {
    const handle = () => {
      setIsShowAppointment(true);
    };
    EM.addListener('ShowAppointment', handle);
    return () => {
      EM.removeListener('ShowAppointment', handle);
    };
  }, []);

  const handle = (value: boolean) => {
    console.log(value);
    setHideHome(value);
  };

  EM.addListener('toggleHome', handle);
  useEffect(() => {
    return () => {
      EM.removeListener('toggleHome', handle);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const day = new Date().getDay();
      const offlineDays = JSON.parse(process.env.REACT_APP_OFFLINE_DAYS);
      if (offlineDays[0] <= day && day <= offlineDays[1]) {
        setShowStatus(true);
      } else {
        setShowStatus(false);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return useMemo(() => {
    if (!showStatus) {
      return <OutOfService />;
    }
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <>
          {!hideHome && (
            <Link
              className='home'
              to={'/'}
              onClick={() => {
                if (localStorage.getItem('fromIndex') === 'true') {
                  localStorage.setItem('fromIndex', '');
                } else {
                  window.scrollTo(0, 0);
                }
              }}>
              <div className='inner'>返回首页</div>
            </Link>
          )}
          <Image>
            <img
              className={'left'}
              src={require('./images/header-left.png')}
              alt='left'
            />
            <img
              className={'right'}
              src={require('./images/header-right.png')}
              alt='right'
            />
          </Image>
          {routes.map(props => (
            <Route
              key={props.path}
              path={props.path}
              component={props.component}
              exact={props.exact}
            />
          ))}
          <Footer src={require('./images/footer.png')} />
          <Coypright>
            <div className='container'>
              <a
                className='text'
                rel='noopener noreferrer'
                href='http://beian.miit.gov.cn/'>
                渝ICP备16013111号-1
              </a>
              <a
                rel='noopener noreferrer'
                href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50011302000420'
                className='beian-code'>
                <img
                  src={require('./images/wangan.png')}
                  alt='img'
                  style={{ float: 'left', margin: '0 10px' }}
                />
                <p className='text'>渝公网安备 50011302000420号</p>
              </a>
            </div>
          </Coypright>
          <Nav>
            {footerItems.map(({ text, onClick, img }) => (
              <div key={text} onClick={onClick}>
                <img src={require(`./images/icon${img}.svg`)} alt='' />
                <span>{text}</span>
              </div>
            ))}
          </Nav>
          {isShowAppointment && (
            <Appointment
              onClose={() => {
                setIsShowAppointment(false);
              }}
            />
          )}
        </>
      </BrowserRouter>
    );
  }, [footerItems, hideHome, isShowAppointment, showStatus]);
};

const Image = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 129px;
  padding: 0 20px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .left {
    width: 322px;
  }
  > .right {
    width: 166px;
  }
`;
const Footer = styled.img`
  width: 100vw;
`;

const Coypright = styled.div`
  width: 100vw;
  background: #000000;
  margin-bottom: 98px;
  padding-bottom: env(safe-area-inset-bottom);
  .container {
    position: relative;
    padding: 40px 20px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    &:after {
      content: '';
      pointer-events: none;
      transform-origin: center;
      position: absolute;
      left: 54px;
      right: 54px;
      top: 0;
      border-top: 2px solid #d4d4d4;
      transform: scaleY(0.5);
    }
  }
  .beian-code {
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    height: 40px;
    line-height: 40px;
  }
  .text {
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    font-size: 22px;
  }
`;

const Nav = styled.footer`
  z-index: 2;
  width: 100vw;
  height: 98px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #eee;
  padding-bottom: env(safe-area-inset-bottom);
  > div {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    img {
      width: 45px;
      height: 45px;
    }
  }
`;

export default hot(module)(App);
