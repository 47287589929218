export default `
01许凌可
Roger Xu
重庆德普外国语学校校长
中国国际学校校长俱乐部创始会员
新学说国际教育研究院首席特邀讲师
TEDxChongqing特邀讲者
混沌重庆特邀演讲嘉宾
教育学博士
descEn
Principal of Chongqing DEPU Foreign Language School
Founder Member of Chinese International School Principals Club
Chief Guest Lecturer of Newschool Insight Media International Education Institute
Guest Lecturer of TEDxChongqing
Guest Lecturer of Hundun Chongqing
PhD Candidate
--
02孙继先
Van Sun
重庆德普外国语学校小学部校长
德普教育（深圳）有限公司副总经理
北京大学国际学校PADIS理事
清华大学电子工程系硕士
descEn
Principal of Bilingual Boarding Primary Department, Chongqing DEPU Foreign Language School
Deputy General Manager of DEPU Education (Shenzhen) Co., Ltd.
Director of International School PADIS in Peking University
Master of Electronic Engineering,Tsinghua University
--
03夏丽琼
Iris Xia
重庆德普外国语学校党总支书记
重庆德普外国语学校小班初中部校长
重庆市首届教学名师、骨干教师、高级教师
重庆市德育专委会理事
北京大学国际学校校长研修班首期成员
重庆市学校艺术教学协会理事
descEn
General Secretary of CPC;
Principal of Smart Junior Department,Chongqing Depu Foreign Language School;
Outstanding teacher, backbone teacher and senior teacher in Chongqing;
Secretary of Moral Education in Chongqing;
Director of Academic Alliance of Principal Class of International School in Peking University;
Director of Chongqing School Art Education Association;
--
04孟姜
Nancy Meng
重庆德普外国语学校双语初中部校长
20年国际教育经历，丰富的海外工作经历
FLTRP、NEPCS等多项国际英语竞赛指导教师
宾夕法尼亚大学积极心理学学者
西南大学高等教育学硕士
descEn
Principal of Bilingual Junior Department, Chongqing DEPU Foreign Language School 
20 years of international education experience, and rich overseas working experience
Advisor for international English proficiency competitions such as FLTRP and NEPCS
Psychologist in Positive Psychology Center, University of Pennsylvania
Master of Higher Education, Southwest University
--
05张韧
Amy Zhang
重庆德普外国语学校国际高中部校长
剑桥国际学校理事会教师发展理事
美国大学理事会授权学校负责人
东亚区剑桥国际学校校长大会特邀讲师
清华大学校长 IPCSL 成员
英国伦敦大学学院国际教育领导力项目
英国利物浦大学金融学硕士
descEn
Principal of International Senior Department , Chongqing DEPU Foreign Language School 
Ditector of Cambridge School Community Board
Principal of Authorized School in the College Board
Guest Lecturer of East Asia Cambridge School Principal Conference 
Member of Principal IPCSL，Tsinghua University
International Education Leadership Programme at Institute of Education, Universtiy College London 
Master of Finance , University of Liverpool	
--
06赵川
重庆德普外国语学校校长助理
原重庆德普外国语学校招生办主任
descEn
ZHAO Chuan
Principal Assistant of Chongqing Depu Foreign Language School
Former Director of Admissions of Chongqing Depu Foreign Language School

`;
