import React from 'react';
import Header from '@components/Header';
import Pop from '@components/Pop';
import StyledComponent from './index.style';
import { rank, Course } from '@pages/InternationalSenior/Data';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router';

export default (props: RouteComponentProps<any>) => {
  const history = useHistory();
  return (
    <StyledComponent>
      <Header
        img={require('./header.png')}
        title='国际高中'
        titleEn='International Senior'
        content={
          <>
            <div>过一个平凡无趣的人生，实在太容易了。</div>
            <div>你可以，不读书、不冒险、不运动、</div>
            <div>不协助、不外出、不折腾……</div>
            <div>但是，人生最后悔的事情就是，</div>
            <div>我本来可以！</div>
          </>
        }
      />
      <div className='content '>
        <div className='section graduate'>
          <div className='title'>我们的毕业生</div>
          <div className='content'>
            <div className='t'>
              每一年，德普毕业生都从这里出发，走向世界各地。
            </div>
            <div className='t'>
              英国、美国、澳大利亚、加拿大、新加坡、中国香港......
              在这些国家和地区的顶尖高校里，有了越来越多德普学子的身影。
            </div>
            <div className='t'>
              我们欣喜于孩子们取得的成绩，他们用自己的付出，让自己的世界更加辽阔。
            </div>
            <div className='title'>德普历届学生部分录取榜单</div>
            <div className='table'>
              {rank.map(x => {
                return (
                  <div className='row' key={x.school}>
                    <div className='col'>{x.country}</div>
                    <div className='col'>{x.school}</div>
                  </div>
                );
              })}
            </div>
            <div
              className='title'
              style={{
                color: 'rgb(23, 125, 224)'
              }}
              onClick={() => {
                history.push('/Graduates?t=0');
                window.scrollTo(0, 0);
                localStorage.setItem('fromIndex', 'true');
              }}>
              点击查看毕业生详细录取信息 {'>>'}
            </div>
          </div>
        </div>
        <div className='section course'>
          <div className='title'>我们的课程</div>
          <div className='content'>
            <div className='t1'>
              世界是平的，地球也不过是一个村。我们相信，懂中国，懂世界，才能在世界的舞台上自由起舞。
            </div>
            <div className='t2'>指针课程-COMPAS</div>
            <div className='t3'>
              {Course.map(item => {
                return (
                  <Pop
                    className='item'
                    key={item.right}
                    content={item.content}
                    leftVW={item.leftVW}>
                    <div className='left'>
                      {item.left.split('').map((x, index) => {
                        return <span key={index}>{x}</span>;
                      })}
                    </div>
                    <div className='right'>{item.right}</div>
                  </Pop>
                );
              })}
            </div>
          </div>
        </div>
        <div className='section level'>
          <div className='title'>为什么是A-Level？</div>
          <div className='content'>
            <div className='t'>
              我们采用了最具竞争力的A-Level国际课程。它由剑桥大学国际考评部（Cambridge
              International）设立，是英国、新加坡、中国香港等国家和地区的国民课程，也是全球学习人数最多，最受欢迎的国际课程。
            </div>

            <div className='title'>A-Level课程优势：</div>
            <div className='list'>
              <div className='item'>
                <img src={require('./icon_check.png')} alt='check' />
                <div>选3科，即可申请世界知名大学；</div>
              </div>
              <div className='item'>
                <img src={require('./icon_check.png')} alt='check' />
                <div>
                  转换大学学分，节省学习时间。就读英澳大学免一年预科，就读美加名校，最高可转换一年学分；
                </div>
              </div>
              <div className='item'>
                <img src={require('./icon_check.png')} alt='check' />
                <div>可申请全球所有国家的大学；</div>
              </div>
              <div className='item'>
                <img src={require('./icon_check.png')} alt='check' />
                <div>多次考试机会，凭最高成绩申请大学，不再一考定终身；</div>
              </div>
            </div>
            <div className='t'>
              我们无法也不希望去要求，所有的孩子都能整齐划一，都能学好每一科目，也痛心于“一考定终身”带来的遗憾。所以，我们选择A-Level课程，让哪怕是偏科的孩子，也能发现自己的天赋。
            </div>
          </div>
        </div>
        <div className='section cq'>
          <div className='title'>
            重庆剑桥国际中心<span className='mini'>/A-Level官方考点</span>{' '}
          </div>
          <div className='content'>
            <div className='t'>
              我们是剑桥大学国际考评部（Cambridge
              International）官方认证的“重庆剑桥国际中心”，是Cambridge
              International授权的剑桥全球统考的考点，也是西南地区首家被Cambridge
              International授权的剑桥教师职业发展中心（PDQ），承担着为中国西南地区剑桥课程教师提供教学教研培训的重任。
            </div>
            <div className='t'>
              作为剑桥大学国际考评部（Cambridge International Education）
              授权的官方考点，德普学生可在本校参加A-Level全球统
              考，日常教室即考场。
            </div>
            <div className='title'>德普与全球平均成绩对比</div>
            <div className='chart'>
              <img src={require('./chart1.svg')} alt='chart1' />
            </div>
            <div className='chart'>
              <img src={require('./chart2.svg')} alt='chart2' />
            </div>
            <div className='t'>
              在剑桥IGCSE全球统考中，德普学生数学平均成绩高出全球平均水平23.8%，商科高出全球水平15.2%；在剑桥A-Level全球统考中，德普学生进阶数学平均成绩高出全球平均水平24.3%，物理高出32.8%，化学高出35.5%。
            </div>
          </div>
        </div>
        <div className='section teacher'>
          <div className='title'>跨文化多元师资</div>
          <div className='content'>
            <div className='t'>
              我们的老师来自英国、法国、美国、印度、澳大利亚、加拿大、巴基斯坦……
            </div>
            <div className='t'>
              只有在跨文化教育环境的冲击和碰撞下，才能塑造出真正意义上的世界公民。
            </div>
            <div className='t'>
              我们请来大量的海归和硕士，汇集了国际教育领域的资深专家，多元文化背景的学科外教，丰富教学或职场经验的海归精英，只为让孩子们站得高一点、再高一点……
            </div>
            <div className='t'>
              他们中有普华永道前审计师、渣打银行的分析师、雅思7分的历史老师、国学院出生的专业国学老师……看过世界的他们，才能更好地引导孩子们看世界。
            </div>
            <div
              className='link'
              onClick={() => {
                props.history.push('/EliteFaculties?t=4');
                window.scrollTo(0, 0);
                localStorage.setItem('fromIndex', 'true');
              }}>
              点击查看师资详细介绍
            </div>
          </div>
        </div>
        <div className='section authentication'>
          <div className='title'>国际认证</div>
          <div className='content'>
            {/* <div className='subTitle'>
              ——我们得到了来自国际国内权威机构的认可，除剑桥大学国际考评部（Cambridge
              International）官方认证的“重庆剑桥国际中心”，全球统考考点和西南地区首家剑桥教师职业发展中心（PDQ），德普还是：
            </div>
            <div className='t'>
              <div>
                美国大学理事会（The College Board）认证学校，授权开设AP课程
              </div>
              <div>
                爱德思学术资质及考试中心(Edexcel Academic Qualifications)
              </div>
              <div>
                英国教育文化协会认证普思考试示范学校（APTIS Model School）
              </div>
              <div> 2019中国新锐国际学校</div>
              <div>2019剑桥国际东亚区大会承办学校</div>
              <div>重庆市外商投资企业协会战略合作伙伴</div>
              <div> 新加坡重庆商会战略合作伙伴</div>
            </div> */}

            <div className='subTitle'>
              我们得到了来自国际国内权威机构的认可，是剑桥大学国际考评部（CIE）官方认证的“重庆剑桥国际中心”，是A-level全球统考官方考点。此外，德普还是：
            </div>
            <div className='t'>
              <div>剑桥教师职业发展中心（PDQ中心）</div>
              <div>美国大学理事会AP认证学校</div>
              <div>Edexcel爱德思学术资质及考试中心认证考点</div>
              <div>全球最大的非营利性国际学校权威认证机构Cognia认证学校</div>
              <div>雅思示范学校</div>
              <div>英国教育文化协会认证普思考试示范学校</div>
              <div>2019中国新锐国际学校</div>
              <div>2019剑桥国际东亚区大会承办学校</div>
              <div>2020年胡润百学·中国百强国际学校</div>
              <div>2021年胡润百学·中国百强国际学校，且位列第43位</div>
              <div>2022年胡润百学·中国百强国际学校，且位列第41位</div>
            </div>

            <div className='subTitle'>
              ——我们提供大学升学的绿色通道，给学生更多的保障：
            </div>
            <div className='t'>
              <div>加拿大多伦多大学绿色通道</div>
              <div>加拿大约克大学绿色通道</div>
              <div>日本立命馆亚洲太平洋大学绿色通道</div>
            </div>
            <div className='subTitle'>
              ——我们也是众多国际主流学科竞赛的赛点，为学生申
              请世界顶尖名校提供了最大的可能性，包括：
            </div>
            <div className='t'>
              <div>澳大利亚科学竞赛BSC</div>
              <div>美国化学竞赛USNCO</div>
              <div>加拿大化学竞赛CCC</div>
              <div>加拿大化学奥赛CCO</div>
              <div>加拿大初中化学奥赛BCCO</div>
              <div>英国化学奥赛UKChO</div>
              <div>澳大利亚化学奥赛ASOC</div>
              <div>美国少年化学家挑战赛YBTC</div>
              <div>美国计算机科学联赛ACSL</div>
              <div>澳大利亚数学竞赛AMC</div>
              <div>美国物理杯AAPT</div>
              <div>英国物理奥赛BPhO</div>
              <div>袋鼠数学竞赛MK</div>
              <div>阿斯丹商赛 ASDAN</div>
              <div>美国数学竞赛AMC/AIME</div>
              <div>全美经济挑战赛NEC</div>
              <div>滑铁卢大学数学竞赛</div>
              <div>欧几里得数学竞赛</div>
            </div>
            <div className='subTitle'>
              ——在各类国际大赛中，我们的学生通过努力与实力，拿回了属于自己的荣誉。包括：
            </div>
            <div className='t'>
              <div>滑铁卢大学数学竞赛全球第65名</div>
              <div>AMC12美国数学竞赛全球前5%（获AIME竞赛资格）</div>
              <div>AMC8美国数学竞赛全球一等奖、三等奖</div>
              <div>澳大利亚信息数学竞赛二等奖</div>
              <div>ASDAN数学竞赛二等奖</div>
              <div>英国物理奥赛全球金奖</div>
              <div>英国皇家化学协会化学新星挑战赛优秀奖</div>
              <div>澳大利亚科学竞赛Big Science二等奖</div>
              <div>环球自然日全球总决赛三等奖</div>
              <div>ASDAN中国模拟商赛银奖</div>
              <div>中国高中生美式辩论赛蝉联冠军</div>
              <div>……</div>
            </div>
          </div>
        </div>
      </div>
    </StyledComponent>
  );
};
